<template>
    <v-card elevation="0" class="pa-0 mt-3 mb-4 item-card" :class="cardClass" @click="action">
        <v-container class="pa-0">
<!--            {{data}}-->
            <v-row no-gutters>
<!--                <template v-for="(dataItem, index) in data">-->
<!--                    <v-col :key="dataItem.id" cols="4">-->
<!--                        <div class="py-2 pl-3">{{ dataItem.text }}：</div>-->
<!--                    </v-col>-->
<!--                    <v-col :key="dataItem.id" :cols="index == data.length - 1 ? 5 : 8">-->
<!--                        <div :class="dataItem.class" class="py-2 pr-2">-->
<!--                            {{ dataItem.value }}-->
<!--                        </div>-->
<!--                    </v-col>-->
<!--                </template>-->
                <v-col cols="12">
                    <div v-if="data.store_name" class="py-2 pl-6">{{ data.store_name }} (NT$ {{data.price}})</div>
                </v-col>
                <v-col cols="12">
                  <div class="py-2 pl-6">
                    {{ $helper.orderTimeFormat(data.created_at) }} 訂單完成 /
                    <span style="color: #009775"
                      >{{ data.status === 'pending' ? '' : updated_at }}
                      {{ data.status === 'pending' ? '尚未付款' : '付款完成' }}</span
                    >
                  </div>
                </v-col>

                <v-col cols="9">
                <div class="py-2 pl-6">
                  匯款帳號末5碼： <span v-if="data.account_no">{{ data.account_no }})</span
                  ><span v-else>無</span>
                </div>
                </v-col>
                <v-col cols="3">
                    <div class="py-2 text-center item-card-btn pointer">
                        {{ actionText }}
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: "secondary",
            validator(value) {
                return ["primary", "secondary", 'lighten', 'dark', 'error'].includes(value);
            },
        },
        data: {
            default: () => [{ text: "label", value: "value" }],
        },
        actionText: {
            type: String,
        },
        action: {
            type: Function,
            default: () => {},
        },
        updated_at: {
          type: String,
          default: '',
        },
    },
    computed: {
        cardClass() {
            if (this.type === "primary") return "item-card--primary";
            if (this.type === "secondary") return "item-card--secondary";
            if (this.type === "lighten") return "item-card--lighten";
            if (this.type === "dark") return "item-card--dark";
            if (this.type === "error") return "item-card--error";
            return "";
        },
    },
};
</script>

<style lang="sass">
.linerp-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important
    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0
  .item-card--primary
    .item-card-btn
      background: #009775
      color: #FFFFFF
  .item-card--secondary
    .item-card-btn
      background: #ffffff
      color: #009775
      border: 1px solid #009775
.provider-store-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important
    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0
  .item-card--primary
    .item-card-btn
      background: #006DE0
      color: #FFFFFF
  .item-card--secondary
    .item-card-btn
      background: #ffffff
      color: #006DE0
      border: 1px solid #006DE0

.item-card--lighten
  .item-card-btn
    background: #ffffff
    color: #00E086
    border: 1px solid #00E086
.item-card--dark
  .item-card-btn
    background: #ffffff
    color: #122A47
    border: 1px solid #122A47
.item-card--error
  .item-card-btn
    background: #ffffff
    color: #E00000
    border: 1px solid #E00000

</style>